import React, { useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { Button, Form, Modal } from 'react-bootstrap';
import Navigation from './Navigation';
import imageCompression from 'browser-image-compression';

const AddEntry = ({ setLatestPostDate }) => {
    const [eventDate, setEventDate] = useState(new Date());
    const [content, setContent] = useState('');
    const [images, setImages] = useState([]);
    const [imageUrls, setImageUrls] = useState([]);
    const [imageIndexToRemove, setImageIndexToRemove] = useState(null);
    const [showModal, setShowModal] = useState(false);
    const navigate = useNavigate();

    const addPost = async () => {
        try {
            await axios.get('https://cshzxy.com/api/check-auth', { withCredentials: true });
        } catch (error) {
            if (error.response && error.response.status === 401) {
                window.alert('给爷爬');
                return;
            } else {
                console.error('Error during authentication', error);
            }
        }

        try {
            const formData = new FormData();
            formData.append('event_date', eventDate);
            formData.append('content', content);
            images.forEach(image => {
                formData.append('images', image);
            });
    
            await axios.post('https://cshzxy.com/api/', formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
                withCredentials: true
            });
            setLatestPostDate(eventDate);
            navigate('/posts');
        } catch (error) {
            console.error('There was an error adding the post!', error);
        }
    };    

    const handleFileUpload = async (e) => {
        const selectedImages = Array.from(e.target.files);
        const newImages = [];
        const newImageUrls = [];
    
        const compressImage = async (image) => {
            try {
                const options = {
                    maxSizeMB: 0.4,
                    maxWidthOrHeight: 800,
                    useWebWorker: true,
                    preserveExif: true
                };
    
                const compressedFile = await imageCompression(image, options);
                newImages.push(compressedFile);
                newImageUrls.push(URL.createObjectURL(compressedFile));
            } catch (error) {
                console.error('Error occurred while compressing the image', error);
            }
        };
    
        await Promise.all(selectedImages.map(compressImage));
        setImages((prev) => [...prev, ...newImages]);
        setImageUrls((prev) => [...prev, ...newImageUrls]);
    };    

    const removeImage = (index) => {
        const updatedImages = images.filter((_, i) => i !== index);
        const updatedImageUrls = imageUrls.filter((_, i) => i !== index);
        setImages(updatedImages);
        setImageUrls(updatedImageUrls);
        setShowModal(false);
    };

    const handleRemoveImageClick = (index) => {
        setImageIndexToRemove(index);
        setShowModal(true);
    };

    return (
        <div className="container">

            <Navigation />

            <form>
                <div className="mb-3">
                    <label className="form-label me-2">啥时候</label>
                    <DatePicker 
                        selected={eventDate} 
                        onChange={(date) => setEventDate(date)} 
                        dateFormat="yyyy-MM-dd"
                        className="form-control"
                    />
                </div>
                
                <div className="mb-3">
                    <label className="form-label">干啥了</label>
                    <textarea 
                        value={content} 
                        onChange={(e) => setContent(e.target.value)} 
                        className="form-control" 
                        rows="8"
                    ></textarea>
                </div>

                <Form.Group className="mb-3">
                    <Form.Label>来点照片</Form.Label>
                    <Form.Control type="file" accept="image/*" multiple onChange={handleFileUpload} />
                </Form.Group>

                <Form.Group className="mb-3">
                <Form.Label>已选：</Form.Label>
                    <div className="d-flex flex-wrap">
                        {images.map((image, index) => (
                            <div key={index} className="me-2 position-relative">
                                <button
                                    type="button"
                                    className="btn-close position-absolute top-0 end-0"
                                    onClick={() => handleRemoveImageClick(index)}
                                ></button>
                                <img
                                    src={URL.createObjectURL(image)}
                                    alt={`Preview ${index}`}
                                    width="100"
                                    height="100"
                                />
                            </div>
                        ))}
                    </div>
                </Form.Group>

                {/* Modal for confirmation */}
                <Modal show={showModal} onHide={() => setShowModal(false)}>
                    <Modal.Header closeButton>
                        <Modal.Title>确认</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>这张真的不要了吗?</Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={() => setShowModal(false)}>
                            还是要吧
                        </Button>
                        <Button variant="danger" onClick={() => removeImage(imageIndexToRemove)}>
                            不要了
                        </Button>
                    </Modal.Footer>
                </Modal>
                
                <Button 
                    variant="outline-primary" 
                    className="float-end mb-3" 
                    onClick={addPost}
                >
                    添加
                </Button>
            </form>
        </div>
    );
};

export default AddEntry;
