import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate, useParams } from 'react-router-dom';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { Button, Form, Modal } from 'react-bootstrap';
import Navigation from './Navigation';
import imageCompression from 'browser-image-compression';

const EditEntry = ({ setLatestPostDate }) => {
    const { id } = useParams();
    const [eventDate, setEventDate] = useState(new Date());
    const [content, setContent] = useState('');
    const [imageData, setImageData] = useState([]);
    const [imageIndexToRemove, setImageIndexToRemove] = useState(null);
    const [showModal, setShowModal] = useState(false);
    const navigate = useNavigate();

    useEffect(() => {
        const fetchPost = async () => {
            try {
                const response = await axios.get(`https://cshzxy.com/api/posts/${id}`, { withCredentials: true });
                const post = response.data;
                setEventDate(new Date(post.event_date));
                setContent(post.content);
                const initialImageData = post.images.map(img => ({
                    url: `https://cshzxy.com/api${img}`,
                    isNew: false,
                }));
                setImageData(initialImageData);
            } catch (error) {
                console.error('There was an error fetching the post!', error);
            }
        };

        fetchPost();
    }, [id]);

    const handleFileUpload = async (e) => {
        const selectedImages = Array.from(e.target.files);
        const newImageData = [];

        const options = {
            maxSizeMB: 0.4,
            maxWidthOrHeight: 800,
            useWebWorker: true,
            preserveExif: true
        };

        await Promise.all(selectedImages.map(async (image) => {
            try {
                const compressedFile = await imageCompression(image, options);
                newImageData.push({
                    file: compressedFile,
                    url: URL.createObjectURL(compressedFile),
                    isNew: true,
                });
            } catch (error) {
                console.error('Error occurred during image compression', error);
            }
        }));

        setImageData(prev => [...prev, ...newImageData]);
    };

    const removeImage = async (index) => {
        const removedImage = imageData[index];
        if (removedImage.isNew === false) {
            try {
                await axios.delete(`https://cshzxy.com/api/images/delete`, {
                    data: {
                        imagePath: removedImage.url.replace('https://cshzxy.com/api', ''),
                        postId: id
                    },
                    withCredentials: true
                });
            } catch (error) {
                console.error('There was an error deleting the image!', error);
            }
        }

        const updatedImageData = imageData.filter((_, i) => i !== index);
        setImageData(updatedImageData);
    };

    const updateEntry = async () => {
        try {
            await axios.get('https://cshzxy.com/api/check-auth', { withCredentials: true });
        } catch (error) {
            if (error.response && error.response.status === 401) {
                window.alert('给爷爬');
                return;
            } else {
                console.error('Error during authentication', error);
            }
        }

        try {
            const formData = new FormData();
            formData.append('event_date', eventDate);
            formData.append('content', content);
            imageData.filter(item => item.isNew).forEach(item => {
                formData.append('images', item.file);
            });

            await axios.put(`https://cshzxy.com/api/edit/${id}`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
                withCredentials: true
            });
            setLatestPostDate(eventDate);
            navigate('/posts');
        } catch (error) {
            console.error('There was an error updating the post!', error);
        }
    };

    return (
        <div className="container">

            <Navigation />

            <form>
                <div className="mb-3">
                    <label className="form-label me-2">啥时候</label>
                    <DatePicker 
                        selected={eventDate} 
                        onChange={(date) => setEventDate(date)} 
                        dateFormat="yyyy-MM-dd"
                        className="form-control"
                    />
                </div>
                
                <div className="mb-3">
                    <label className="form-label">干啥了</label>
                    <textarea 
                        value={content} 
                        onChange={(e) => setContent(e.target.value)} 
                        className="form-control" 
                        rows="8"
                    ></textarea>
                </div>
            </form>

            <div className="mb-3">
                <Form.Label>来点照片</Form.Label>
                <Form.Control type="file" accept="image/*" multiple onChange={handleFileUpload} />
            </div>

            <div className="mb-3">
                <Form.Label>已选：</Form.Label>
                <div className="d-flex flex-wrap">
                    {imageData.map((image, index) => (
                        <div key={index} className="me-2 position-relative">
                            <button
                                type="button"
                                className="btn-close position-absolute top-0 end-0"
                                onClick={() => {
                                    setImageIndexToRemove(index);
                                    setShowModal(true);
                                }}
                            ></button>
                            <img
                                src={image.url}
                                alt={`Preview ${index}`}
                                width="100"
                                height="100"
                            />
                        </div>
                    ))}
                </div>
            </div>

            {/* Modal for confirmation */}
            <Modal show={showModal} onHide={() => setShowModal(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>确认</Modal.Title>
                </Modal.Header>
                <Modal.Body>这张真的不要了吗?</Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setShowModal(false)}>
                        还是要吧
                    </Button>
                    <Button variant="danger" onClick={() => {
                        removeImage(imageIndexToRemove);
                        setShowModal(false);
                    }}>
                        不要了
                    </Button>
                </Modal.Footer>
            </Modal>

            <Button 
                variant="outline-primary" 
                className="float-end mb-3" 
                onClick={updateEntry}
            >
                更改
            </Button>
        </div>
    );
};

export default EditEntry;
