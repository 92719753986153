import React from 'react';
import Navigation from "./Navigation";

const Cover = () => {
    const coverStyle = {
        marginTop: '-80px',
        minHeight: '100vh',
        background: `url('/cover.jpg') no-repeat center center fixed`, 
        backgroundSize: 'cover',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        color: 'white'
    };

    const textStyle = {
        backgroundColor: 'rgba(0, 0, 0, 0.35)',
        padding: '20px',
        borderRadius: '10px',
        textAlign: 'center'
    };

    return (
        <div style={coverStyle}>
            <Navigation />
            <div style={textStyle}>
                <h1>欢迎</h1>
                <p>
                    这里记录csh和zxy的生活。我们于2020年相识，2023年结婚，今后还有很长的路要走～
                </p>
            </div>
        </div>
    );
}

export default Cover;
