import React from 'react';
import { Routes, Route } from 'react-router-dom';
import BlogDisplay from './BlogDisplay';
import AddEntry from './AddEntry';
import EditEntry from './EditEntry';
import Cover from "./Cover";

const Main = () => {
  const [latestPostDate, setLatestPostDate] = React.useState(null);

  return (
    <div>
      <Routes>
        <Route path="/" element={<Cover />} />
        <Route path="/posts" element={<BlogDisplay latestPostDate={latestPostDate} />} />
        <Route path="/add" element={<AddEntry setLatestPostDate={setLatestPostDate} />} />
        <Route path="/edit/:id" element={<EditEntry setLatestPostDate={setLatestPostDate} />} />
      </Routes>
    </div>
  );
}

export default Main;
