import React, { useState, useEffect, useLayoutEffect } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { Dropdown, ButtonGroup } from 'react-bootstrap';
import Navigation from './Navigation';
import { Carousel } from 'react-responsive-carousel';
import "react-responsive-carousel/lib/styles/carousel.min.css";

const BlogDisplay = ({ latestPostDate }) => {
    const [posts, setPosts] = useState([]);
    const [selectedYear, setSelectedYear] = useState('');
    const [triggerScroll, setTriggerScroll] = useState(false);
    const navigate = useNavigate();

    React.useEffect(() => {
        if (latestPostDate) {
            const year = new Date(latestPostDate).getFullYear();
            setSelectedYear(year.toString());
        }
    }, [latestPostDate, setSelectedYear]);

    useLayoutEffect(() => {
        if (latestPostDate) {
            const isoDate = new Date(latestPostDate).toISOString();
            const postElement = document.querySelector(`[data-date='${isoDate}']`);
            if (postElement) {
                postElement.scrollIntoView({ behavior: 'smooth' });
            }
        }
    }, [latestPostDate, posts]);

    useEffect(() => {
        const fetchPosts = async () => {
            try {
                const response = await axios.get('https://cshzxy.com/api/', { withCredentials: true });
                const fetchedPosts = response.data;
    
                // Sort posts by date in ascending order
                const sortedPosts = fetchedPosts.sort((a, b) => 
                    new Date(a.event_date) - new Date(b.event_date)
                );
    
                setPosts(sortedPosts);
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };
        fetchPosts();
    }, []);    

    useEffect(() => {
        if (posts.length > 0) {
            const years = [...new Set(posts.map(post => 
                new Date(post.event_date).getFullYear()
            ))].filter(year => !isNaN(year)).sort((a, b) => a - b);

            if (!years.includes(Number(selectedYear))) {
                setSelectedYear('');
            }

            if (years.length > 0 && !selectedYear) {
                setSelectedYear(years[years.length-1].toString());
            }
        }
    }, [posts, selectedYear]);  

    const filteredPosts = posts.filter(post => 
        new Date(post.event_date).getFullYear().toString() === selectedYear
    );

    const deletePost = async (id) => {
        if (window.confirm('Are you sure you want to delete this post?')) {
            try {
                await axios.get('https://cshzxy.com/api/check-auth', { withCredentials: true });
            } catch (error) {
                if (error.response && error.response.status === 401) {
                    window.alert('给爷爬');
                    return;
                } else {
                    console.error('Error during authentication', error);
                }
            }

            try {
                await axios.delete(`https://cshzxy.com/api/delete/${id}`, { withCredentials: true });
                setPosts(posts => posts.filter(post => post._id !== id));
            } catch (error) {
                console.error('There was an error deleting the post!', error);
            }
        }
    };

    return (
        <div className="container">

            <Navigation />

            <div className="d-flex justify-content-center my-3">
                <select 
                    className="form-select"
                    style={{ width: '200px' }} 
                    value={selectedYear} 
                    onChange={(e) => setSelectedYear(e.target.value)}
                >
                    {[...new Set(posts.map(post => 
                        new Date(post.event_date).getFullYear()
                    ))].filter(year => !isNaN(year))
                    .sort((a, b) => a - b)
                    .map(year => (
                        <option key={year} value={year}>{year}</option>
                    ))}
                </select>
            </div>

            <div className="row">
                <div className="col-md-2"></div> {/* Empty column for left spacing */}
                <div className="col-md-8">
                    {filteredPosts.map(post => (
                        <div key={post._id} data-date={post.event_date} className="mb-4">
                            <div className='d-flex flex-row flex-wrap align-items-center justify-content-between mb-3 mt-3'>
                                <div className='d-flex justify-content-center align-items-center'>
                                    <h3 className='fs-2'>{new Date(post.event_date).toLocaleDateString()}</h3>
                                </div>
                                <div className="d-flex justify-content-end align-items-center">
                                    <Dropdown as={ButtonGroup}>
                                        <Dropdown.Toggle
                                            variant="outline-dark"
                                            id={`dropdown-${post._id}`}
                                        >
                                            &#8942;
                                        </Dropdown.Toggle>
                                        <Dropdown.Menu>
                                            <Dropdown.Item
                                                onClick={() => navigate(`/edit/${post._id}`)}
                                            >
                                                编辑
                                            </Dropdown.Item>
                                            <Dropdown.Item
                                                onClick={() => deletePost(post._id)}
                                            >
                                                删除
                                            </Dropdown.Item>
                                        </Dropdown.Menu>
                                    </Dropdown>
                                </div>
                            </div>
                            
                            <p style={{ whiteSpace: 'pre-line', wordWrap: 'break-word', marginBottom: '32px' }} className='fs-6'>{post.content}</p>

                            <Carousel
                                showArrows={true}
                                interval={3200}
                                autoPlay={true}
                                infiniteLoop={true}
                                showStatus={false}
                                stopOnHover={false}
                                showThumbs={false}
                            >
                                {post.images.map((img, index) => (
                                    <div key={index}>
                                        <div style={{ width: '100%', height: '320px', background: '#FFFFFF', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                            <img src={`https://cshzxy.com/api${img}`} alt="" style={{ maxHeight: '100%', maxWidth: '100%', objectFit: 'contain', background: '#FFFFFF' }} />
                                        </div>
                                    </div>
                                ))}
                            </Carousel>

                        </div>
                    ))}
                </div>
                <div className="col-md-2"></div> {/* Empty column for right spacing */}
            </div>
        </div>
    );
}

export default BlogDisplay;
