import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useLocation, Link } from 'react-router-dom';
import { Modal, Button, Form, Alert } from 'react-bootstrap';

import './Navigation.css'

const Navigation = () => {
    const [isAuthenticated, setIsAuthenticated] = useState(false);
    const [showLoginModal, setShowLoginModal] = useState(false);
    const [password, setPassword] = useState('');
    const [error, setError] = useState('');
    const location = useLocation();

    useEffect(() => {
        const checkAuthentication = async () => {
            try {
                const response = await axios.get('https://cshzxy.com/api/check-auth', { withCredentials: true });
        
                if (response.status === 200) {
                    setIsAuthenticated(true);
                } else if (response.status === 401) {
                    setIsAuthenticated(false);
                }
            } catch (error) {
                if (error.response && error.response.status === 401) {
                    setIsAuthenticated(false);
                } else {
                    console.error('Error checking authentication status', error);
                    setIsAuthenticated(false);
                }
            }
        };

        checkAuthentication();
    }, []);

    const handleLogin = async () => {
        try {
            await axios.post('https://cshzxy.com/api/login', { password }, {
                withCredentials: true
            });

            setIsAuthenticated(true);
            setShowLoginModal(false);
            setError('');
        } catch (error) {
            setError('别来沾边');
        }
    };

    const handleLogout = async () => {
        try {
            await axios.post('https://cshzxy.com/api/logout', {
                withCredentials: true
            });
            setIsAuthenticated(false);
            alert('溜咯!');
        } catch (error) {
            console.error('Error logging out', error);
        }
    };

    return (
        <div>
            <nav className="navbar navbar-expand navbar-light fixed-top no-padding" style={{backgroundColor: '#dcd6f7', width: '100%'}}>
                <div className="container-fluid d-flex align-items-center justify-content-between">
                    <Link className="navbar-brand flex-no-grow" style={{ fontFamily: '"Brush Script MT", cursive', fontSize: '28px' }} to="/">csh&zxy's page</Link>
                    <div className="d-flex flex-no-grow">
                        <ul className="navbar-nav">
                            <li className="nav-item">
                            <Link 
                                className={`nav-link ${location.pathname === '/posts' ? 'active' : ''}`} 
                                to="/posts"
                            >
                                记录
                            </Link>
                            </li>
                            <li className="nav-item">
                            <Link 
                                className={`nav-link ${location.pathname === '/add' ? 'active' : ''}`} 
                                to="/add"
                            >
                                添加
                            </Link>
                            </li>
                        </ul>
                        {isAuthenticated ? (
                            <Button variant="outline-dark" onClick={handleLogout} className="ms-2 flex-no-grow">溜了</Button>
                        ) : (
                            <Button variant="outline-dark" onClick={() => setShowLoginModal(true)} className="ms-2 flex-no-grow">验证</Button>
                        )}
                    </div>
                </div>
            </nav>

            <Modal show={showLoginModal} onHide={() => setShowLoginModal(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>让俺看看你是谁</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form.Group>
                        <Form.Label>我的电脑密码是？</Form.Label>
                        <Form.Control 
                            type="password" 
                            value={password} 
                            onChange={(e) => setPassword(e.target.value)} 
                            onKeyDown={(e) => {
                                if (e.key === 'Enter') {
                                    e.preventDefault();
                                    handleLogin();
                                }
                            }}
                        />
                        {error && <Alert variant="danger" className="mt-2">{error}</Alert>}
                    </Form.Group>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setShowLoginModal(false)}>
                        取消
                    </Button>
                    <Button variant="primary" onClick={handleLogin}>
                        给你
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    );
};

export default Navigation;
