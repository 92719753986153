import React, { useEffect } from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import Main from './Main';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-datepicker/dist/react-datepicker.css';
import 'bootstrap/dist/js/bootstrap.bundle.min';
import axios from 'axios';

axios.defaults.withCredentials = true;

const App = () => {
  useEffect(() => {
    document.title = 'csh&zxy\'s page';
  }, []);
  return (
    <div className="App">
      <Router>
        <Main />
      </Router>
    </div>
  );
}

export default App;
